import { Heading, View, Text, useTheme, Flex, Button, Link } from '@aws-amplify/ui-react'
import * as React from 'react'
import { BiCopy, BiLink } from "react-icons/bi"
import { VpnWithMetrics } from '../pages/Home'
import { formatDuration, intervalToDuration } from 'date-fns'
import { useLocation, useNavigate } from 'react-router-dom'


export function titleToId(children: any): string | undefined {
  const result = children.replace(/\W/g, '').toLowerCase()
  // console.debug(`Returning id for ${children}: ${result}`)
  return result
}


function copyToClipboard(text: any) {
  navigator.clipboard.writeText(text)
}

export function Scrollable({ children }: { children: any }) {
  const { pathname, hash, key } = useLocation();

  React.useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change


  return <View>
    {children}
  </View>
}

export function Linkable({ id, children }: { id: string | undefined, children: any }) {
  const { pathname, hash, key } = useLocation();
  const navigate = useNavigate()

  const url = pathname + '#' + id

  return id ?
    <View><Flex justifyContent={'center'} alignItems={'center'} gap={'0.2rem'}>{children}<Button padding='0.2rem' margin='0.2rem' size='small' onClick={() => navigate(url)}><BiLink /></Button></Flex></View> :
    <View>{children}</View>
}

export function MainTitle({ children }: { children: any }) {
  return (
    <Scrollable>
      <Heading textAlign={'center'} level={1} paddingBlock={"2rem"} id={titleToId(children)}>
        <Linkable id={titleToId(children)}>
          {children}
        </Linkable>
      </Heading>
    </Scrollable>
  )
}

export function MainSubTitle({ children }: { children: any }) {

  return (
    <Scrollable>
      <Heading textAlign={'center'} level={2} padding={"1.5rem"} id={titleToId(children)}>
        <Linkable id={titleToId(children)}>
          {children}
        </Linkable>
      </Heading>
    </Scrollable>
  )
}


export function TitleSection({ children }: { children: any }) {
  return (
    <Scrollable>
      <Heading textAlign={'left'} level={3} paddingBlock={"1rem"} id={titleToId(children)}>
        <Linkable id={titleToId(children)}>
          {children}
        </Linkable>
      </Heading>
    </Scrollable>
  )
}

export function TitleSubSection({ children }: { children: any }) {
  return (
    <Scrollable>

      <Heading textAlign={'left'} level={4} paddingBlock={"1rem"} id={titleToId(children)}>
        <Linkable id={titleToId(children)}>
          {children}
        </Linkable>
      </Heading>
    </Scrollable>
  )
}

export function TitleSubSubSection({ children }: { children: any }) {
  return (
    <Scrollable>

      <Heading level={5} paddingBlock={"1rem"} id={titleToId(children)}>
        <Linkable id={titleToId(children)}>
          {children}
        </Linkable>
      </Heading>
    </Scrollable>
  )
}


export function Code({ children }: { children: any }) {
  const { tokens } = useTheme()

  return (
    <View
      padding={tokens.space.xs}
      backgroundColor={tokens.colors.background.tertiary}>
      <Flex direction='row' justifyContent={'space-between'}>
        <Text fontFamily={'monospace'} id={'Stuff'}>
          {children}
        </Text>
        <Button size='small' onClick={() => copyToClipboard(children)}>
          <BiCopy />
        </Button>
      </Flex>
    </View>
  )
}

export function WideView({ children }: { children: any }) {
  const { tokens } = useTheme()
  return (
    <View width={"auto"} textAlign={"justify"}>
      {children}
    </View>
  )
}

export function ShortCenteredTextView({ children }: { children: any }) {
  const { tokens } = useTheme()
  return (
    <Flex direction={'column'} alignItems={'center'}  >
      {children}
    </Flex>
  )
}

export function Emph({ children }: { children: any }) {
  return (
    <u>{children}</u>
  )
}

export function Ref({ children }: { children: any }) {
  return <Link href={children} isExternal={true}>[ref]</Link>
}

export function reactUpdateVpnWithMetrics(vpnsWithMetrics: VpnWithMetrics[], vpnWithMetrics: VpnWithMetrics) {
  return vpnsWithMetrics.map(vpnWM =>
    vpnWM.vpn.vpnId == vpnWithMetrics.vpn.vpnId ?
      { vpn: vpnWithMetrics.vpn, metrics: vpnWithMetrics.metrics } :
      vpnWM
  )
}

export function convertSecondsToHMS(seconds: number): string {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 }) // Convert seconds to milliseconds
  return formatDuration(duration)
}