import React from 'react'
import { TitleSection, ShortCenteredTextView, Emph } from '../components/Common'
import { Instructions } from '../components/static/Instructions'
import { Button, Card, Link, Text, View } from '@aws-amplify/ui-react'
import { MyTmpVpnClient } from '@mytmpvpn/mytmpvpn-client/client'
import Pricing from './Pricing'
import { useNavigate } from 'react-router-dom'


export function Welcome({ client }: { client: MyTmpVpnClient }) {
    return (
        <View>
            <ShortCenteredTextView>
                <TitleSection>Subscription-free Vpn Service</TitleSection>
                <View>
                    MyTmpVpn is a prepaid Virtual Private Network service.<br />
                    Each vpn provisioned is:
                    <ul>
                        <li><b>Secure:</b> runs <Link href="https://wireguard.com">WireGuard</Link></li>
                        <li><b>Isolated:</b> runs on its own server with no ssh access</li>
                        <li><b>Temporary:</b> will be destroyed eventually</li>
                    </ul>
                    Need more info? Read the <Link href='/faq'>FAQ</Link> or <Link href='/contactus'>contact us</Link>.
                </View>
                <Card>
                    <Text textAlign={"center"} fontSize={'xxx-large'} fontWeight={'bold'}>
                        <Link href='/signup'>Sign up now and claim<br />
                            <Emph>10 free peanuts!</Emph>
                        </Link>
                    </Text>
                </Card>
            </ShortCenteredTextView >
            <Pricing client={client} />
        </View>
    )
}