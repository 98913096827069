import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { useAuthenticator, Button, Heading, Text, View, Grid, Card, Flex, Link, useTheme, Placeholder, Menu, MenuButton, MenuItem, Divider } from '@aws-amplify/ui-react'
import { Logo } from './static/Logo'
import ErrorBanner from './ErrorBanner'
import { useErrorContext } from '../ErrorContext'
import { WideView } from './Common'

function Loading() {
    return (
        <WideView>Loading...</WideView>
    )
}

export function HelpMenu() {
    const navigate = useNavigate()

    return (
        <Menu
            trigger={
                <MenuButton variation='menu'>
                    Help
                </MenuButton>
            }
        >
            <MenuItem onClick={() => navigate('/doc')}>Documentation</MenuItem>
            <MenuItem onClick={() => navigate('/faq')}>FAQ</MenuItem>
            <MenuItem onClick={() => navigate('/issue')}>Issue</MenuItem>
            <MenuItem onClick={() => navigate('/support')}>Support</MenuItem>

        </Menu>
    )
}

export function Layout() {
    const { authStatus, signOut, user } = useAuthenticator((context) => [
        context.authStatus,
        context.signOut,
        context.user
    ])

    // console.log(`AuthStatus in Layout(): ${JSON.stringify(authStatus)}`)
    const navigate = useNavigate()
    const { error, setError } = useErrorContext()
    const { tokens } = useTheme()

    function logOut() {
        signOut()
        navigate('/login')
    }

    if (authStatus === 'configuring') return <Loading />

    return (
        <center>
            <Grid templateRows="0fr 0fr 1fr 0fr"
                alignContent={"center"}
                alignItems={"center"}
            >
                <Flex alignItems={'center'}
                    justifyContent={"center"}
                >
                    <Logo />
                    <Button variation='menu' onClick={() => navigate('/pricing')}>Pricing</Button>
                    <HelpMenu />
                    <Button variation='menu' onClick={() => navigate('/contribute')}>Contribute</Button>
                    <Button variation='menu' onClick={() => navigate('/makemoney')}>Make Money</Button>
                    {authStatus !== 'authenticated' ? (
                        <Button onClick={() => navigate('/login')}>Sign In</Button>
                    ) : (
                        <>
                            <Menu
                                trigger={
                                    <MenuButton>
                                        {user ?
                                            user.attributes?.email ? user.attributes.email :
                                                user.attributes?.phone_number ? user.attributes.phone_number
                                                    : user.username
                                            : 'Account'
                                        }
                                    </MenuButton>
                                }
                            >
                                <MenuItem onClick={() => navigate('/password')}>Password Change</MenuItem>
                                <MenuItem variation='warning' onClick={() => logOut()}>
                                    <Text variation='warning'>Logout</Text>
                                </MenuItem>
                                <Divider />
                                <MenuItem variation='destructive' onClick={() => navigate('/unregister')}>
                                    <Text variation='error'>Delete Your Account</Text>
                                </MenuItem>
                            </Menu>
                        </>
                    )}
                </Flex>
                <Card>
                    {error && <ErrorBanner error={error} setError={setError} />}
                </Card>
                <Card>
                    <Outlet />
                </Card>
                <Card>
                    <Flex direction={"row"}
                        justifyContent={"center"}
                    >
                        <Card>
                            <Flex direction={"column"}>
                                <Heading level={3}>About</Heading>
                                <Button variation='menu' size='small' onClick={() => navigate('/contactus')}>Contact Us</Button>
                            </Flex>
                        </Card>
                        <Card>
                            <Flex direction={"column"}>
                                <Heading level={3}>Legal</Heading>
                                <Button variation='menu' size='small' onClick={() => navigate('/privacy')}>Privacy Policy</Button>
                                <Button variation='menu' size='small' onClick={() => navigate('/terms')}>Terms Of Use</Button>
                            </Flex>
                        </Card>
                    </Flex>
                </Card>
            </Grid>
        </center>

    )
}
